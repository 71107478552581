<template>
  <div class="free-ad">
      <div class="row margin-0 justify-content-center">
          <div class="col-md-12 col-lg-10 col-xl-10 col-12 pad-0">
              <div class="row margin-0">
                  <div class="col-md-6 col-12">
                    <!-- <h4 class="margin-bottom-15" v-if="payload.id">Update Banner Ad</h4>
                    <h4 class="margin-bottom-15" v-else>Create new Banner Ad</h4> -->
                    <div class="ad-block pad-10 margin-bottom-10">
                        <h6 class="margin-bottom-10"><b>Basic Information</b></h6>
                        <div class="form-group">
                            <label for="">Label<span class="req">*</span></label>
                            <input type="text" class="form-control" v-model="payload.label" placeholder="Label">
                            <span class="error" v-if="error && error.label">{{error.label}}</span>
                        </div>
                    </div>
                    <div class="ad-block margin-bottom-10 pad-10">
                        <h6 class="margin-bottom-10"><b>Banner &amp; Actions</b></h6>
                        <div class="form-group">
                            <label for="">Banner<span class="req">*</span></label><br>
                            <div class="banner-preview" v-if="payload.url">
                                <img :src="payload.url" alt="" class="img-fluid" srcset="">
                            </div>
                            <div v-else class="upload-block" @click="$refs.imgfile.click()">
                                <ion-icon name="image-outline"></ion-icon><br>Browse Files
                            </div>
                            <span class="error" v-if="error && error.url">{{error.url}}</span>
                            <template v-if="upload_status && upload_status!=={}">
                                <div class="row margin-0">
                                    <template v-for="(itm, idx) in upload_status">
                                        <div :key="idx" class="col-12 pad-10 margin-top-10" style="border: 1px solid #ddd;">
                                            <span>{{itm.filename}}</span>
                                            <div>
                                                <span style="font-size: 0.6em;">{{itm.status}}</span>
                                                <span style="font-size: 0.6em;" :class="{'text-success':itm.progress===100, 'text-warning':itm.progress>1 && itm.progress<100}" class="f-right">{{itm.progress}}%</span>
                                            </div>
                                            <div class="progress margin-top-0" style="height:5px;">
                                                <div :class="{'bg-success':itm.progress===100, 'bg-warning':itm.progress>1 && itm.progress<100}" class="progress-bar" :style="{'width':itm.progress+'%'}"></div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </template>
                        </div>
                        <div class="form-group">
                            <label for="">Action<span class="req">*</span></label>
                            <!-- <div class="form-sub-group">
                                <select class="form-control" v-model="link_mode" style="max-width:100px">
                                    <option value="link">Visit Link or website</option>
                                    <option value="call">Call to a number</option>
                                </select>
                                <input type="text" class="form-control" v-model="payload.link" placeholder="On click redirect link">
                            </div> -->
                            <input type="text" class="form-control" v-model="payload.link" placeholder="Url to open the link">
                            <span class="error" v-if="error && error.link">{{error.link}}</span>
                        </div>
                    </div>
                    <div class="ad-block pad-10">
                        <h6 class="margin-bottom-10"><b>Duration &amp; Pricing</b></h6>
                        <div class="row margin-0">
                            <div class="col-md-4 pad-left-0">
                                <div class="form-group">
                                    <label for="">Start Date <span class="req">*</span></label>
                                    <input type="datetime-local" v-model="payload.start_on" class="form-control">
                                </div>
                            </div>
                            <div class="col-md-4 pad-left-0">
                                <div class="form-group">
                                    <label for="">No of days <span class="req">*</span></label>
                                    <input type="number" min="1" v-model="payload.days" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div class="row margin-0">
                            <div class="col-12 pad-0 pad-bottom-10">
                            <span class="txt-grey margin-bottom-5">Make Banner visible till:</span><br>
                            <button class="btn btn-light btn-sm margin-right-15" @click="payload.days=7">For a Week (7 days)</button>
                            <button class="btn btn-light btn-sm" @click="payload.days=30">For a Month (30 days)</button>
                            </div>
                        </div>
                        <div class="row margin-0">
                            <div class="col-5 pad-0">
                                <div class="form-group">
                                    <label for="">Start Date</label>
                                    <span class="d-block">{{new Date(payload.start_on).toLocaleDateString()}}</span>
                                </div>
                            </div>
                            <div class="col-2 pad-0 text-left">
                                <label for="" class=""></label>
                                <span class="d-block">To</span>
                            </div>
                            <div class="col-5 pad-0">
                                <div class="form-group">
                                    <label for="">End Date</label>
                                    <span class="d-block" v-if="payload.end_on">{{payload.end_on.toLocaleDateString()}}</span>
                                </div>
                            </div>
                            <div class="col-12 pad-0">
                                <div class="form-group">
                                    <label for="">Estimated Amount</label>
                                    <div class="d-block">
                                    <span style="color:green;"><b><span class="fa fa-inr"></span> {{payload.price}}</b></span>
                                    <span class="margin-left-30" style="color:orangered;text-decoration:line-through;"><b><span class="fa fa-inr"></span> {{payload.price * 2}}</b></span> <span class="badge badge-warning margin-left-10">Flat 50% off</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 pad-10 text-center">
                                <input style="display:none;" @change="uploadFile()" id="imgfile" type="file" accept="image/*" ref="imgfile"/>
                                <!-- <button class="btn btn-success margin-right-10" v-if="payload.id" @click="updateAd()">Update</button> -->
                                <button class="btn btn-success margin-right-10" :disabled="payload.price<=0" @click="createAd()">Create &amp; pay <span class="fa fa-inr"></span>{{payload.price}}</button>
                                <button class="btn btn-danger" @click="$router.push('/my-banners/')">Cancel</button>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                      <div class="ad-block pad-15">
                        <h6 class="margin-bottom-10"><b>Possible ad impressions places on app</b></h6>
                        <br><br>
                        <div class="row margin-0" v-if="payload.url">
                            <div class="col-md-6 col-12">
                                <h6>- HomeScreen Slider</h6>
                                <div class="page-skelton">
                                    <div class="home-slider">
                                        <div class="home-slider-left"></div>
                                        <div class="home-slider-banner">
                                            <img :src="payload.url" class="img-fluid" alt="">
                                        </div>
                                        <div class="home-slider-right"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <h6>- Product preview page</h6>
                                <div class="page-skelton">
                                    <div class="row margin-0">
                                        <div class="col-7">
                                            <div class="page-skelton-img">
                                                <p>
                                                    <br><br><br><br><br>
                                                </p>
                                                <p>
                                                    <br>
                                                </p>
                                                <p>
                                                    <br><br><br><br>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-5 pad-left-0">
                                            <div class="page-skelton-info">
                                                <p>
                                                    <br><br>
                                                </p>
                                                <p>
                                                    <br>
                                                </p>
                                                <p>
                                                    <br>
                                                </p>
                                            </div>
                                            <img :src="payload.url" class="img-fluid" alt="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <!-- saving, edit, get pop -->
    <div class="pop" v-if="saving">
        <div class="message-board">
            <h5>Please wait....</h5>
            <template v-if="saving==='create'">
                <img src="/img/priority.png" class="save-img" alt="">
                <p class="text-center">Saving and adding your ad in priority list.</p>
            </template>
            <template v-else-if="saving==='get'">
                <img src="/img/get.webp" class="save-img" alt="">
                <p class="text-center">Retreving your Ad and creating form.</p>
            </template>
            <template v-else-if="saving==='update'">
                <img src="/img/priority.png" class="save-img" alt="">
                <p class="text-center">Updating and adding your ad in priority list.</p>
            </template>
        </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
    props: ['id'],
    data() {
    return {
        profile: JSON.parse(localStorage.getItem('user_details')) || null,
        payload: {
            label: null,
            link: null,
            url: null,
            data: {},
            supports: [],
            assets: [],
            start_on: new Date(),
            days: 1,
            ends_on: null,
            price: null
        },
        link_mode: 'link',
        error: null,
        saving: null,
        upload_status: {},
        payment_detail: null
    }
  },
  watch: {
    'payload.days': function() {
      this.calculateDate();
    },
    'payload.start_on': function() {
      this.calculateDate();
    },
    'payload.url': function() {
    },
    link_mode: function() {
        console.log(this.link_mode);
    }
  },
  created() {
      this.calculateDate();
      if(this.id) {
        //   this.getProduct();
      }
  },
  methods: {
    addDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    calculateDate() {
      this.payload.price = this.payload.days>0?(this.payload.days * 20):0;
      this.payload.end_on = this.addDays(new Date(this.payload.start_on), parseInt(this.payload.days));
    },
    createAd() {
        const that = this;
        if(that.validateData()) {
            that.saving = 'create';
            that.$cs.banner.create({data: that.payload})
            .then(res=>{
                that.payment_detail = res.data;
                that.makePayment();
                // that.$router.push('/my-banners/')
                that.saving = null;
            },err=>{
                that.saving = null;
                console.error(err);
            })
        }
    },
    updateAd() {
        const that = this;
        if(that.validateData()) {
            that.saving = 'update';
            that.$cs.banner.update({
                resource: `${that.$cs.banner.api}${that.id}/`,
                data: that.payload})
            .then(res=>{
                that.saving = null;
                that.$router.push('/my-banners/')
            },err=>{
                that.saving = null;
                console.error(err);
            })
        }
    },
    makePayment() {
        const that = this;
        var options = that.payment_detail.payment_link.payment_link
        // after payment success handler
        options.handler = function (response) {
        response.banner_id = options.notes.banner_id
        let resource = `${that.$cs.banner.api}${response.banner_id}/paymentsucess/`;
        that.$cs.banner.update({
            resource: resource,
            data:response
        })
        .then(res => {
            that.$router.push('/my-banners/')
            console.log(res.data);
        }, err=> {
            console.log(err.response);
        })
        }
        options.modal = {
            ondismiss: function(response) {
            console.log(response, "Checkout form closed");
            }
        };
        // Razorpay pop for payment
        var rzp = new Razorpay(options)
        rzp.open()
        event.preventDefault()
    },
    getProduct() {
        const that = this;
        that.saving = 'get';
        that.$cs.banner.list({
            resource: `${that.$cs.banner.api}${that.id}/`
        })
        .then(res=>{
            that.payload = res.data;
            that.saving = null;
        },err=>{
            that.saving = null;
            console.error(err);
        })
    },
    removeImg(index) {
        this.payload.images.splice(index, 1);
        if(index === 0) {
            this.payload.thumbnail = this.payload.images[0];
        }
    },
    resetPayload() {
        this.payload = {
            label: null,
            link: null,
            url: null,
            banner_type: "banner",
            data: {},
        };
        this.error = null;
        this.upload_status = {};
    },
    validateData() {
      const that = this;
      that.error = {};
      var proceed = true;
      if (that.payload.label === null || that.payload.label === "") {
        this.error.label = "label field is required";
        proceed = false;
      }
      if (that.payload.url === null || that.payload.url === "") {
        this.error.url = "Banner is required";
        proceed = false;
      }
      if (that.payload.link === null || that.payload.link === "") {
        this.error.link = "Action link is required";
        proceed = false;
      }
      console.log(that.error);
      return proceed;
    },
    uploadFile() {
        const that = this;
        // var file1 = new File();
        var file1 = document.getElementById('imgfile').files[0];
        const fnm = new Date().getTime();
        if (file1 !== undefined) {
            that.upload_status[fnm] = {
                filename: file1.name,
                status: 'Validating Image',
                progress: 0
            }
            // convert image to webp
            new Promise(function (resolve, reject) {
                let rawImage = new Image();
                rawImage.addEventListener("load", function () {
                    resolve(rawImage);
                });
                rawImage.src = URL.createObjectURL(file1);
            }).
            then(function (rawImage) {
                // Convert image to webp ObjectURL via a canvas blob
                return new Promise(function (resolve, reject) {
                    let canvas = document.createElement('canvas');
                    let ctx = canvas.getContext("2d");
                    canvas.width = rawImage.width;
                    canvas.height = rawImage.height;
                    // document.querySelector("#canvasBlock").appendChild(canvas);
                    ctx.drawImage(rawImage, 0, 0);
                    // let logoWatermark = document.getElementById("logowatermark");
                    // let dX = canvas.width - (canvas.height * 0.22);
                    // let dY = canvas.height * 0.80;
                    // let dH = canvas.height * 0.20;
                    // ctx.drawImage(logoWatermark, dX, dY, dH, dH);
                    // debugger
                    canvas.toBlob(function (blob) {
                        var file245 = new File([blob], 'dot.webp', {type:'image/webp'});
                        console.log(file245);
                        // resolve(URL.createObjectURL(blob));
                        resolve(file245);
                    }, "image/webp");
                });
            }).
            then(function (file2) {
                that.upload_status[fnm].status = 'Read to Upload';
                that.$cs.profile
                .getSignedLinkForUpload({
                    fileName: Math.random().toString(36).substr(2,9)+'.webp',
                    fileType: "image/webp",
                    access: true,
                    type: 'banner',
                })
                .then((response) => {
                    const signedData = response.data;
                    const options = {
                    headers: {
                        'Content-Type': "image/webp",
                    },
                    onUploadProgress(progressEvent) {
                        that.upload_status[fnm].status = 'Uploading...',
                        that.upload_status[fnm].progress = `${Math.round((progressEvent.loaded * 100) / progressEvent.total,)}`;
                    },
                    }; // options
                    axios
                    .put(signedData.signed_url, file2, options)
                    .then(() => {
                        that.payload.url = signedData.url;
                        that.upload_status[fnm].status = 'Done';
                        that.upload_status[fnm].progress = 100;
                    })
                    .catch(() => {
                        that.upload_status[fnm].status = 'Failed';
                    });
                })
                .catch(() => {
                    that.upload_status[fnm].status = 'Failed';
                });
                // var file2 = fetch(imageURL,{mode:"cors"})
                // .then(res => res.blob())
                // .then(blob => {
                //     const file = new File([blob], 'dot.webp', {type:'image/webp'});
                // });
            });
        }
    },
  }
}
</script>
<style lang="stylus" scoped>
.pmv-img
    height 60vh
    display flex
    text-align center
    align-items center
    justify-content center
.pop
  position fixed
  top 0
  right 0
  left 0
  bottom 0
  z-index 10000
  background-color rgba(0,0,0,0.6)
  padding 25px 15px
  text-align center
  display flex
  justify-content center
  align-items center
  .message-board
    background-color #fff
    padding 20px
    display inline-block
    border-radius 3px
    width 50%
    text-align center
    .save-img
        margin 10px
        max-width 100px
    p
        margin-top 2vh
        color #444
@media (max-width: 767px)
    .pop
        .message-board
            width 75%
.free-ad
    padding 5vh 0px
    .ad-block
        background-color #ffffff
        border 1px solid #ddd
.thumb-list
    list-style none
    margin 0px
    padding 0px
    width 100%
    max-width 100%
    overflow-x scroll
    li
        width 5vw
        height 5vw
        max-height 5vw
        border 1px solid #ddd
        margin 5px
        float left
        cursor pointer
        display flex
        text-align center
        align-items center
        justify-content center
        position relative
        .fa-times-close
            position absolute
            background-color orangered
            color white
            height 20px
            width 20px
            font-size 12px
            border-radius 50%
            cursor pointer
            display flex
            align-items center
            justify-content center
            top -7px
            right -7px
        &.active
            border-color red
        img
            max-width 100%
            max-height 15vw
.upload-block
    border 1px solid #ddd
    border-radius 4px
    background-color #eee
    text-align center
    font-size 1em
    padding 10px
    font-weight 400
    cursor pointer
    ion-icon
        font-size 3em
.banner-preview
    img
        border 1px solid #ddd
        // max-width 500px
.form-sub-group
    display flex
    select
        border-radius 4px 0px 0px 4px
        width auto
    input
        border-radius 0px 4px 4px 0px
.page-skelton
    min-height 30vh
    border 1px solid #ddd
.page-skelton-img, .page-skelton-info
    p
        background-color #eee
        display block
        margin 10px 0px
.home-slider
    border 1px solid #ddd
    padding 10px 0px
    display flex
    .home-slider-left
        width 10%
        background-color #eee
        border-radius 0px 4px 4px 0px
    .home-slider-banner
        width 78%
        margin 0 1%
    .home-slider-right
        width 10%
        background-color #eee
        border-radius 4px 0px 0px 4px
@media (max-width: 767px)
    .free-ad
        padding 2vh 0px
    .thumb-list
        li
            width 15vw
            min-height 15vw
</style>
